import { default as indextlVkpqAU26Meta } from "/vercel/path0/pages/assistant/index.vue?macro=true";
import { default as translate0045bbPyldMeta } from "/vercel/path0/pages/assistant/translate.vue?macro=true";
import { default as assistantnT0XG703FVMeta } from "/vercel/path0/pages/assistant.vue?macro=true";
import { default as bulletinqZf4MM99VQMeta } from "/vercel/path0/pages/bulletin.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as leaves2nDZeJEK0kMeta } from "/vercel/path0/pages/leaves.vue?macro=true";
import { default as mailchimpMg1Z7FEkunMeta } from "/vercel/path0/pages/mailchimp.vue?macro=true";
import { default as missivezTpbAitFl7Meta } from "/vercel/path0/pages/missive.vue?macro=true";
import { default as searchBIFC2dE21WMeta } from "/vercel/path0/pages/search.vue?macro=true";
import { default as tasksecF44zWm3gMeta } from "/vercel/path0/pages/tasks.vue?macro=true";
import { default as timesheetsPLxSvFbLH8Meta } from "/vercel/path0/pages/timesheets.vue?macro=true";
export default [
  {
    name: assistantnT0XG703FVMeta?.name ?? undefined,
    path: assistantnT0XG703FVMeta?.path ?? "/assistant",
    meta: assistantnT0XG703FVMeta || {},
    alias: assistantnT0XG703FVMeta?.alias || [],
    redirect: assistantnT0XG703FVMeta?.redirect,
    component: () => import("/vercel/path0/pages/assistant.vue").then(m => m.default || m),
    children: [
  {
    name: indextlVkpqAU26Meta?.name ?? "assistant",
    path: indextlVkpqAU26Meta?.path ?? "",
    meta: indextlVkpqAU26Meta || {},
    alias: indextlVkpqAU26Meta?.alias || [],
    redirect: indextlVkpqAU26Meta?.redirect,
    component: () => import("/vercel/path0/pages/assistant/index.vue").then(m => m.default || m)
  },
  {
    name: translate0045bbPyldMeta?.name ?? "assistant-translate",
    path: translate0045bbPyldMeta?.path ?? "translate",
    meta: translate0045bbPyldMeta || {},
    alias: translate0045bbPyldMeta?.alias || [],
    redirect: translate0045bbPyldMeta?.redirect,
    component: () => import("/vercel/path0/pages/assistant/translate.vue").then(m => m.default || m)
  }
]
  },
  {
    name: bulletinqZf4MM99VQMeta?.name ?? "bulletin",
    path: bulletinqZf4MM99VQMeta?.path ?? "/bulletin",
    meta: bulletinqZf4MM99VQMeta || {},
    alias: bulletinqZf4MM99VQMeta?.alias || [],
    redirect: bulletinqZf4MM99VQMeta?.redirect,
    component: () => import("/vercel/path0/pages/bulletin.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: leaves2nDZeJEK0kMeta?.name ?? "leaves",
    path: leaves2nDZeJEK0kMeta?.path ?? "/leaves",
    meta: leaves2nDZeJEK0kMeta || {},
    alias: leaves2nDZeJEK0kMeta?.alias || [],
    redirect: leaves2nDZeJEK0kMeta?.redirect,
    component: () => import("/vercel/path0/pages/leaves.vue").then(m => m.default || m)
  },
  {
    name: mailchimpMg1Z7FEkunMeta?.name ?? "mailchimp",
    path: mailchimpMg1Z7FEkunMeta?.path ?? "/mailchimp",
    meta: mailchimpMg1Z7FEkunMeta || {},
    alias: mailchimpMg1Z7FEkunMeta?.alias || [],
    redirect: mailchimpMg1Z7FEkunMeta?.redirect,
    component: () => import("/vercel/path0/pages/mailchimp.vue").then(m => m.default || m)
  },
  {
    name: missivezTpbAitFl7Meta?.name ?? "missive",
    path: missivezTpbAitFl7Meta?.path ?? "/missive",
    meta: missivezTpbAitFl7Meta || {},
    alias: missivezTpbAitFl7Meta?.alias || [],
    redirect: missivezTpbAitFl7Meta?.redirect,
    component: () => import("/vercel/path0/pages/missive.vue").then(m => m.default || m)
  },
  {
    name: searchBIFC2dE21WMeta?.name ?? "search",
    path: searchBIFC2dE21WMeta?.path ?? "/search",
    meta: searchBIFC2dE21WMeta || {},
    alias: searchBIFC2dE21WMeta?.alias || [],
    redirect: searchBIFC2dE21WMeta?.redirect,
    component: () => import("/vercel/path0/pages/search.vue").then(m => m.default || m)
  },
  {
    name: tasksecF44zWm3gMeta?.name ?? "tasks",
    path: tasksecF44zWm3gMeta?.path ?? "/tasks",
    meta: tasksecF44zWm3gMeta || {},
    alias: tasksecF44zWm3gMeta?.alias || [],
    redirect: tasksecF44zWm3gMeta?.redirect,
    component: () => import("/vercel/path0/pages/tasks.vue").then(m => m.default || m)
  },
  {
    name: timesheetsPLxSvFbLH8Meta?.name ?? "timesheets",
    path: timesheetsPLxSvFbLH8Meta?.path ?? "/timesheets",
    meta: timesheetsPLxSvFbLH8Meta || {},
    alias: timesheetsPLxSvFbLH8Meta?.alias || [],
    redirect: timesheetsPLxSvFbLH8Meta?.redirect,
    component: () => import("/vercel/path0/pages/timesheets.vue").then(m => m.default || m)
  }
]