import type { PostHog } from "posthog-js";
import posthog from "posthog-js";

export default defineNuxtPlugin({
  name: "posthog",
  parallel: true,
  setup() {
    const { posthogHost, posthogPublicKey } = useRuntimeConfig().public;
    const posthogClient = posthog.init(posthogPublicKey, {
      api_host: posthogHost,
      autocapture: false,
      loaded: (posthog) => {
        if (process.dev)
          posthog.debug();
      },
    }) as PostHog;

    const router = useRouter();
    router.afterEach((to) => {
      nextTick(() => {
        posthog.capture("$pageview", {
          current_url: to.fullPath,
        });
      });
    });

    return {
      provide: {
        posthog: posthogClient,
      },
    };
  },
});
