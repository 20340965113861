export default defineAppConfig({
  ui: {
    primary: "blue",
    gray: "cool",

    formGroup: {
      description: "text-gray-500 dark:text-gray-400 text-xs",
      help: "text-xs",
    },

    selectMenu: {
      width: "w-full min-w-fit",
      input: "block min-w-fit w-64 focus:ring-transparent text-sm px-3 py-1.5 text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-800 border-0 border-b border-gray-200 dark:border-gray-700 focus:border-inherit sticky -top-1 -mt-1 mb-1 -mx-1 z-10 placeholder-gray-400 dark:placeholder-gray-500 focus:outline-none",

      option: {
        base: "cursor-default hover:cursor-pointer select-none relative flex items-center justify-between gap-1",
      },
    },
  },
});
