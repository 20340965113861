import * as Sentry from "@sentry/vue";

export default defineNuxtPlugin({
  name: "sentry",
  parallel: true,
  setup(nuxtApp) {
    const { sentryDsn } = useRuntimeConfig().public;
    const isDev = process.dev;

    Sentry.init({
      enabled: !isDev,
      app: nuxtApp.vueApp,
      dsn: sentryDsn,
      environment: isDev ? "dev" : "prod", // Just in case we enable Sentry in staging
      integrations: [
        Sentry.browserTracingIntegration({
          router: useRouter(),
        }),
        Sentry.replayIntegration(),
      ],
      logErrors: isDev,
      attachProps: true,
      autoSessionTracking: true,
      trackComponents: true,
      timeout: 3000,
      hooks: ["activate", "create", "destroy", "mount", "update"],

      tracesSampleRate: 1.0,
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 0.5,

      beforeSend(event, hint) {
      // Check if it is an exception, and if so, log it.
        if (event.exception) {
          console.error(isDev ? hint.originalException : `[Exception handled by Sentry]: (${hint.originalException})`, { event, hint });
        }

        // Continue sending to Sentry
        return event;
      },
    });

    return {
      provide: {
        sentry: {
          setUser: Sentry.setUser,
          captureError: Sentry.captureException,
        },
      },
    };
  },
});
