import { defineFormKitConfig } from "@formkit/vue";
import { generateClasses } from "@formkit/themes";
import { genesisIcons } from "@formkit/icons";
import { createAutoAnimatePlugin, createAutoHeightTextareaPlugin } from "@formkit/addons";
import { createProPlugin, inputs } from "@formkit/pro";
import tailwindTheme from "./formkit-tailwind-theme";

export default defineFormKitConfig(() => {
  const config = useRuntimeConfig();

  return {
    icons: {
      ...genesisIcons,
    },
    config: {
      classes: generateClasses(tailwindTheme),
    },
    plugins: [
      createProPlugin(config.public.formkitProKey, inputs),
      createAutoAnimatePlugin(),
      createAutoHeightTextareaPlugin(),
    ],
    messages: {
      en: {
        validation: {
          required() {
            return "Required";
          },
        },
      },
    },
  };
});
